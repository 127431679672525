.header, .menu, .content {
  box-sizing: border-box;
}

.app {
  min-height: 90%;
  display: flex;
  min-width: 1024px;
}

.app-bar {
  background-color: #253b6e !important;
  padding: 10px !important;
  margin-bottom: 5px;
}

html, #root, body {
  height: 100%;
  margin: 0px;
}

body {
  font-family: Roboto, Arial, sans-serif;
}

.app-body {
  display: flex;
  flex: 1;
}

.content {
  position: relative;
  flex: 1;
}

.circular{
  position:absolute;  
  left:0; right:0;
  top:0; bottom:0;
  margin:auto;
}
